import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faEdit,
  faQrcode,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import CodeWithCopy from "./CodeWithCopy";

const QRRow = ({ key, qrCode, onEdit, onDelete, onChange, isSelected }) => {
  return (
    <tr key={qrCode.id}>
      <td>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onChange(qrCode.id)}
        />
      </td>
      <td>{qrCode.id}</td>
      <td>
        <CodeWithCopy code={qrCode.code} />
      </td>
      <td>{qrCode.passcode_type}</td>
      <td>{qrCode.category_name}</td>
      <td>
        {qrCode.is_assigned === 1 ? (
          <span class="badge bg-success">Yes</span>
        ) : (
          <span class="badge bg-secondary">No</span>
        )}
      </td>
      <td>
        {qrCode.is_printed === 1 ? (
          <span class="badge bg-success">Yes</span>
        ) : (
          <span class="badge bg-secondary">No</span>
        )}
      </td>
      <td>
        {qrCode.is_scanned === 1 ? (
          <span class="badge bg-success">Yes</span>
        ) : (
          <span class="badge bg-secondary">No</span>
        )}
      </td>
      <td>
        {qrCode.is_production === 1 ? (
          <span class="badge bg-success">Yes</span>
        ) : (
          <span class="badge bg-secondary">No</span>
        )}
      </td>
      <td>
        <Link to={qrCode.qr_image_url} className="link-secondary me-3">
          <FontAwesomeIcon
            icon={faQrcode}
            style={{ cursor: "pointer" }}
            title="Open QR Image"
            size="2x"
          />
        </Link>
        <Link to={qrCode.barcode_image_url} className="link-secondary">
          <FontAwesomeIcon
            icon={faBarcode}
            style={{ cursor: "pointer" }}
            title="Open Barcode Image"
            size="2x"
          />
        </Link>
      </td>
      <td>
        {/* ====== EDIT BUTTON + MODAL ====== */}
        <Button
          variant="secondary"
          className="btn-sm me-2"
          onClick={() => onEdit(qrCode)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>

        <button
          className="btn btn-danger btn-sm"
          onClick={() => onDelete(qrCode.id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  );
};

export default QRRow;
