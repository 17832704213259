import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

const OrderRow = ({
  order,
  indexKey,
  handleAssignModal,
}) => {
  return (
    <tr key={indexKey}>
      <td>{order.id}</td>
      <td>{order.order_id}</td>
      <td>{order.qrcode_id}</td>
      <td>{order.category_name}</td>
      <td>{order.order_item_name}</td>
      <td>
        <ul style={{ paddingLeft: '1rem'}}>
          <li>
            <strong>Email:</strong> {order.meta_data.email}
          </li>
          <li>
            <strong>Phone:</strong> {order.meta_data.phone}
          </li>
        </ul>
      </td>
      {/* <td>{order.meta_data.phone}</td> */}
      <td>
        <ul style={{ paddingLeft: '1rem'}}>
          {Object.entries(order.shipping_address).map(([index, value]) => (
            <li key={index}>{`${index}: ${value}`}</li>
          ))}
        </ul>
      </td>
      <td>
        <ul style={{ paddingLeft: '1rem'}}>
          <li>Created at: {order.created_at}</li>
          <li>Assinged at: {order.assigned_date}</li>
        </ul>
      </td>
      <td>{order.total_price ?? 'N/A'}</td>
      <td>
        {/* ====== Assign btn */}
        <Button
          variant={order.assigned_to_production ? "secondary" : "success"}
          className="btn-sm"
          onClick={() => handleAssignModal(order)}
          disabled={order.assigned_to_production}
        >
          <FontAwesomeIcon icon={faQrcode}/>{" "}
          {order.assigned_to_production ? "Assigned" : "Assign QR"}
        </Button>
      </td>
    </tr>
  );
};

export default OrderRow;
