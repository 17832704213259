import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import QrcodesPage from "./pages/dashboard/QrcodesPage";
import DashboardLayout from "./components/layout/DashboardLayout";
import WelcomeMessage from "./pages/dashboard/WelcomeMessage";
import SwapPage from "./pages/dashboard/SwapPage";
import SettingPage from "./pages/dashboard/SettingPage";
import SocialPage from "./pages/dashboard/SocialPage";
import OrdersPage from "./pages/dashboard/OrdersPage";
import NotFoundPage from "./pages/NotFoundPage";
import FaqPage from "./pages/dashboard/FaqPage";
import MemoryPage from "./pages/dashboard/MemoryPage";
import { isAuthenticated } from "./services/authService";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated() ? (
              <Navigate replace to="/dashboard" />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<WelcomeMessage />} />
          <Route path="qrcodes" element={<QrcodesPage />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="swap" element={<SwapPage />} />
          <Route path="memory/assign" element={<MemoryPage />} />
          <Route path="socials" element={<SocialPage />} />
          <Route path="faqs" element={<FaqPage />} />
          <Route path="settings" element={<SettingPage />} />
        </Route>

        {/* More routes can be added here */}

        {/* The 404 Page route, make sure it's the last one */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
