import React, { useCallback, useEffect, useState, useMemo } from "react";
import OrderRow from "../../components/order/OrderRow";
import axios from "axios";
import Paginate from "../../components/shared/Paginate";
import {
  getToken,
  redirectToHome,
  removeToken,
} from "../../services/authService";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [qrCodesOptions, setQrCodesOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [qrcodeIdFilter, setQrcodeIdFilter] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showQrModal, setShowQrModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [modalSearchQuery, setModalSearchQuery] = useState("");
  const [currentOpenedOrder, setCurrentOpenedOrder] = useState({});
  const [qrFormData, setQrFormData] = useState({ order_id: null, qrcode_id: null });

  const perPageOptions = useMemo(() => [10, 25, 50, 100], []);

  const fetchOrders = useCallback(async () => {
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/orders`, {
        params: { search: searchQuery, page: currentPage, qrcode_id: qrcodeIdFilter, page_size: itemsPerPage },
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders(response.data.content.data);
      setTotalPages(response.data.content.last_page);
      setLoading(false);
    } catch (err) {
      setError(JSON.stringify(err.response.data.error_description[0]));
      alert(err.response.data.error_description[0]);
      if (err.response.data.error_code === 401 && window.location.pathname !== "/") {
        removeToken();
        redirectToHome();
      }
    }
  }, [searchQuery, currentPage, qrcodeIdFilter, itemsPerPage]);

  const fetchQrCodesOptions = useCallback(async () => {
    try {
      const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/qrcodes`, {
        params: { all: 1, production: 1, assigned: 0, search: modalSearchQuery },
        headers: { Authorization: `Bearer ${token}` },
      });
      const formattedOptions = response.data.content.data.map((qr) => ({
        value: qr.id,
        label: `${qr.id} - ${qr.code}`,
      }));
      setQrCodesOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching QR codes:", error);
    }
  }, [modalSearchQuery]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    fetchQrCodesOptions();
  }, [fetchQrCodesOptions]);

  const handleCloseForm = () => {
    setShowQrModal(false);
    setModalError("");
  };

  const handleAssignModal = useCallback((order) => {
    setCurrentOpenedOrder(order);
    setShowQrModal(true);
    setQrFormData({ order_id: order.id, qrcode_id: order.qrcode_id });
  }, []);

  const handleAssignQrSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
      return;
    }

    if (!qrFormData.qrcode_id || !qrFormData.order_id) {
      setModalError("Please fill in all required fields correctly.");
      return;
    }

    const formData = new FormData();
    formData.append("qrcode_id", qrFormData.qrcode_id);
    formData.append("order_id", qrFormData.order_id);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/order/assign`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.result === "success") {
        setShowQrModal(false);
        setTotalPages(response.data.content.last_page);
        setLoading(false);
        setModalError("");
        fetchOrders(); // Refresh orders after successful assignment
      }
    } catch (error) {
      console.error("Error assigning a memory:", error.response.data.error_description[0]);
      setModalError(error.response.data.error_description[0]);
    }
  };

  const handlePerPageChange = useCallback((e) => {
    const newPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newPerPage);
    setCurrentPage(1);
  }, []);

  const handleQrFormChange = useCallback((selectedOption) => {
    setQrFormData((prev) => ({ ...prev, qrcode_id: selectedOption ? selectedOption.value : null }));
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleFilterChange = useCallback((e) => {
    setQrcodeIdFilter(e.target.value);
  }, []);

  return (
    <div className="orders-page">
      <h3 className="my-5">Orders</h3>

      <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-md-5">
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9\s]{13,19}"
          maxLength="19"
          name="qrcode_id"
          onChange={handleFilterChange}
          className="form-control input-filters me-4"
          placeholder="Filter by QRCode ID"
        />
        <input
          type="text"
          name="search"
          onChange={handleSearchChange}
          className="form-control input-filters"
          placeholder="Type here to search"
        />
      </div>
      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>ID</th>
                  <th style={{ width: "auto" }}>WP Order ID</th>
                  <th style={{ width: "auto" }}>QR ID</th>
                  <th style={{ width: "auto" }}>Category</th>
                  <th style={{ width: "auto" }}>Product Name</th>
                  <th style={{ width: "auto" }}>E-mail/Phone</th>
                  <th style={{ width: "20%" }}>Shipping Address</th>
                  <th style={{ width: "auto" }}>Date</th>
                  <th style={{ width: "auto" }}>Total Price</th>
                  <th style={{ width: "10%" }}>Options</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <OrderRow
                    order={order}
                    key={order.id}
                    handleAssignModal={handleAssignModal}
                  />
                ))}
              </tbody>
            </table>
            <Modal show={showQrModal} onHide={handleCloseForm}>
              <Modal.Header closeButton>
                <Modal.Title>Assign QRCode To Order</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="order-details mb-4">
                  <table className="table table-sm table-bordered bg-light">
                    <tbody>
                      <tr>
                        <th className="bg-light text-muted" scope="row">Order ID:</th>
                        <td className="bg-light text-muted">{currentOpenedOrder?.id}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-muted" scope="row">Email:</th>
                        <td className="bg-light text-muted">{currentOpenedOrder?.meta_data?.email}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-muted" scope="row">Phone:</th>
                        <td className="bg-light text-muted">{currentOpenedOrder?.meta_data?.phone}</td>
                      </tr>
                      <tr>
                        <th className="bg-light text-muted" scope="row">Shipping Address:</th>
                        <td className="bg-light text-muted">
                          {currentOpenedOrder?.shipping_address?.full_shipping_address}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Form onSubmit={handleAssignQrSubmit}>
                  <Form.Group className="my-3">
                    <Form.Label>QR Codes</Form.Label>
                    <Select
                      id="qrcode_id"
                      name="qrcode_id"
                      value={qrCodesOptions.find(
                        (option) => option.value === qrFormData.qrcode_id
                      )}
                      onChange={handleQrFormChange}
                      options={qrCodesOptions}
                      isClearable
                      isSearchable
                      placeholder="Search and select QR code"
                    />
                  </Form.Group>
                  <div className="alert alert-warning" role="alert">
                    <strong>Please be aware:</strong> Selecting the "Assign"
                    action will associate this QR code with the current order.
                    This action may affect existing data linked to the QR code,
                    including <strong>Memories, Security Credentials</strong>,
                    and <strong>User Ownership.</strong>
                  </div>
                  {modalError && (
                    <div className="alert alert-danger my-3">{modalError}</div>
                  )}
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      margin: "20px 0",
                      float: "right",
                    }}
                  >
                    Assign
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <label htmlFor="perPageSelect" className="me-2">
                Items per page:
              </label>
              <select
                id="perPageSelect"
                value={itemsPerPage}
                onChange={handlePerPageChange}
                className="form-select form-select-sm d-inline-block w-auto"
              >
                {perPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex justify-content-center">
              <nav aria-label="Page navigation">
                <ul className="pagination flex-wrap">
                  <Paginate
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    className="pagination-sm flex-wrap"
                  />
                </ul>
              </nav>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrdersPage;